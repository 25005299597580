import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

class Changelog extends React.Component {
    render() {
        return (
            <div>
                <h2 className="text-success">Notes E FOR MAD (<FormattedMessage id="Version" /> {process.env.REACT_APP_VERSION})</h2>
                <h4>Mise à jour du 12/12/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.7.0)</strong>
                        <ul>
                            <li>
                                Suppression de la date maximum de 2 ans pour les mercuriales
                                <span className="jira-issue">673</span>
                            </li>
                            <li>
                                Ajout d'un indicateur à l'approche de la fin d'une mercuriale (30 jours avant), avec la possibilité de modifier la date de fin
                                <span className="jira-issue">671</span>
                            </li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">672</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 13/11/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.6.7)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">665</span></li>
                                    <li><span className="jira-issue">662</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 19/09/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.6.6)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">663</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li><strong>Application Web (v1.6.5)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">657</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 18/06/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.6.4)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">655</span></li>
                                    <li><span className="jira-issue">654</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 17/06/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.6.3)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">653</span></li>
                                    <li><span className="jira-issue">652</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 15/06/2024</h4>
                <ul>
                    <li><strong>Application Web (v1.6.2)</strong>
                        <ul>
                            {/*
                            <li>Interfaçage avec LOMACO ON LINE<span className="jira-issue">511</span><span className="jira-issue">578</span>
                                <ul>
                                    <li>Possibilité de lier les comptes clients</li>
                                    <li>Possibilité de lier les mercuriales</li>
                                    <li>Synchronisation automatique des états de commandes</li>
                                </ul>
                            </li>
                            */}
                            <li>Évolution de la nomenclature des références commandes<span className="jira-issue">625</span></li>
                            <li>Ajout d'un écran permettant de visualiser les prochains renouvellements d'équipements patient<span className="jira-issue">499</span></li>
                            <li>Implémentation d'une gestion de documents partagés<span className="jira-issue">489</span></li>
                            <li>Ajout d'une couche de sécurité dans les envois de documents sensibles par courriel<span className="jira-issue">141</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue e4bizz">85</span></li>
                                    <li><span className="jira-issue">645</span></li>
                                    <li><span className="jira-issue">644</span></li>
                                    <li><span className="jira-issue">643</span></li>
                                    <li><span className="jira-issue">641</span></li>
                                    <li><span className="jira-issue">640</span></li>
                                    <li><span className="jira-issue">629</span></li>
                                    <li><span className="jira-issue">628</span></li>
                                    <li><span className="jira-issue">627</span></li>
                                    <li><span className="jira-issue">626</span></li>
                                    <li><span className="jira-issue">622</span></li>
                                    <li><span className="jira-issue">614</span></li>
                                    <li><span className="jira-issue">613</span></li>
                                    <li><span className="jira-issue">610</span></li>
                                    <li><span className="jira-issue">609</span></li>
                                    <li><span className="jira-issue">604</span></li>
                                    <li><span className="jira-issue">603</span></li>
                                    <li><span className="jira-issue">602</span></li>
                                    <li><span className="jira-issue">600</span></li>
                                    <li><span className="jira-issue">597</span></li>
                                    <li><span className="jira-issue">596</span></li>
                                    <li><span className="jira-issue">594</span></li>
                                    <li><span className="jira-issue">592</span></li>
                                    <li><span className="jira-issue">586</span></li>
                                    <li><span className="jira-issue">583</span></li>
                                    <li><span className="jira-issue">582</span></li>
                                    <li><span className="jira-issue">581</span></li>
                                    <li><span className="jira-issue">579</span></li>
                                    <li><span className="jira-issue">471</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.4.3)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue e4bizz">85</span></li>
                                    <li><span className="jira-issue">648</span></li>
                                    <li><span className="jira-issue">646</span></li>
                                    <li><span className="jira-issue">645</span></li>
                                    <li><span className="jira-issue">644</span></li>
                                    <li><span className="jira-issue">641</span></li>
                                    <li><span className="jira-issue">640</span></li>
                                    <li><span className="jira-issue">638</span></li>
                                    <li><span className="jira-issue">636</span></li>
                                    <li><span className="jira-issue">635</span></li>
                                    <li><span className="jira-issue">633</span></li>
                                    <li><span className="jira-issue">627</span></li>
                                    <li><span className="jira-issue">626</span></li>
                                    <li><span className="jira-issue">609</span></li>
                                    <li><span className="jira-issue">604</span></li>
                                    <li><span className="jira-issue">603</span></li>
                                    <li><span className="jira-issue">582</span></li>
                                    <li><span className="jira-issue">471</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 13/10/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.5.1)</strong>
                        <ul>
                            <li>Afficher la date de la commande dans les PDF<span className="jira-issue">574</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">577</span></li>
                                    <li><span className="jira-issue">576</span></li>
                                    <li><span className="jira-issue">568</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.4.0)</strong>
                        <ul>
                            <li>Les données d'un audit MAG en cours peuvent être récupérées en cas de fermeture inopinée de l'application<span className="jira-issue">572</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 01/10/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.5.0)</strong>
                        <ul>
                            <li>Permettre aux patients et aux aidants d'accèder à un espace personnel<span className="jira-issue">540</span></li>
                            <li>Permettre la suppression d'un compte collaborateur<span className="jira-issue">530</span></li>
                            <li>Possibilité de classer et de modifier les points de contrôle pour les dispositifs<span className="jira-issue">528</span></li>
                            <li>Synchronisation automatique avec MUST G5 des états de commandes (en déploiement)<span className="jira-issue">504</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">571</span></li>
                                    <li><span className="jira-issue">569</span></li>
                                    <li><span className="jira-issue">565</span></li>
                                    <li><span className="jira-issue">564</span></li>
                                    <li><span className="jira-issue">563</span></li>
                                    <li><span className="jira-issue">562</span></li>
                                    <li><span className="jira-issue">558</span></li>
                                    <li><span className="jira-issue">556</span></li>
                                    <li><span className="jira-issue">554</span></li>
                                    <li><span className="jira-issue">553</span></li>
                                    <li><span className="jira-issue">552</span></li>
                                    <li><span className="jira-issue">551</span></li>
                                    <li><span className="jira-issue">548</span></li>
                                    <li><span className="jira-issue">547</span></li>
                                    <li><span className="jira-issue">546</span></li>
                                    <li><span className="jira-issue">545</span></li>
                                    <li><span className="jira-issue">544</span></li>
                                    <li><span className="jira-issue">543</span></li>
                                    <li><span className="jira-issue">542</span></li>
                                    <li><span className="jira-issue">541</span></li>
                                    <li><span className="jira-issue">537</span></li>
                                    <li><span className="jira-issue">536</span></li>
                                    <li><span className="jira-issue">533</span></li>
                                    <li><span className="jira-issue">532</span></li>
                                    <li><span className="jira-issue">529</span></li>
                                    <li><span className="jira-issue">517</span></li>
                                    <li><span className="jira-issue">488</span></li>
                                    <li><span className="jira-issue">487</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.3.0)</strong>
                        <ul>
                            <li>Refonte technique des audits comptoir (borne et tablette)<span className="jira-issue">561</span></li>
                            <li>Permettre aux patients et aux aidants d'accèder à un espace personnel<span className="jira-issue">540</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">573</span></li>
                                    <li><span className="jira-issue">571</span></li>
                                    <li><span className="jira-issue">570</span></li>
                                    <li><span className="jira-issue">559</span></li>
                                    <li><span className="jira-issue">554</span></li>
                                    <li><span className="jira-issue">550</span></li>
                                    <li><span className="jira-issue">547</span></li>
                                    <li><span className="jira-issue">546</span></li>
                                    <li><span className="jira-issue">545</span></li>
                                    <li><span className="jira-issue">533</span></li>
                                    <li><span className="jira-issue">531</span></li>
                                    <li><span className="jira-issue">530</span></li>
                                    <li><span className="jira-issue">488</span></li>
                                    <li><span className="jira-issue">487</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 12/07/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.4.2)</strong>
                        <ul>
                            <li>Correction d'une erreur bloquant l'envoi de notifications de nouvelles commandes par email<span className="jira-issue">525</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">527</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 10/07/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.4.1)</strong>
                        <ul>
                            <li>Correction d'une erreur potentielle d'inversion des quantités commandées entre deux articles synchronisés avec MUST G5<span className="jira-issue">517</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">519</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 07/07/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.4.0)</strong>
                        <ul>
                            <li>Prise en charge des déclinaisons MUST G5 en synchronisation pour les mercuriales<span className="jira-issue">444</span></li>
                            <li>Amélioration du fichier d'export sur les erreurs de synchronisation<span className="jira-issue">495</span></li>
                            <li>Refonte du menu d'action associé à la gestion des patients<span className="jira-issue">505</span></li>
                            <li>Notification par mail des demandes de nouvel audit<span className="jira-issue">480</span></li>
                        </ul>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">520</span></li>
                                    <li><span className="jira-issue">512</span></li>
                                    <li><span className="jira-issue">508</span></li>
                                    <li><span className="jira-issue">507</span></li>
                                    <li><span className="jira-issue">492</span></li>
                                    <li><span className="jira-issue">485</span></li>
                                    <li><span className="jira-issue">486</span></li>
                                    <li><span className="jira-issue">485</span></li>
                                    <li><span className="jira-issue">484</span></li>
                                    <li><span className="jira-issue">483</span></li>
                                    <li><span className="jira-issue">482</span></li>
                                    <li><span className="jira-issue">445</span></li>
                                    <li><span className="jira-issue">431</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.2.0)</strong>
                        <ul>
                            <li>Rendre le prescripteur facultatif lors des interventions sur les équipements<span className="jira-issue">516</span></li>
                            <li>Amélioration de la détection de la connexion réseau<span className="jira-issue">509</span></li>
                            <li>Refonte du menu d'action associé à la gestion des patients<span className="jira-issue">505</span></li>
                            <li>Ajout du type "SAV" dans la liste des motifs d'intervention sur les équipements<span className="jira-issue">474</span></li>
                            <li>Refonte de la visionneuse PDF<span className="jira-issue">469</span></li>
                            <li>Dans les audits, permettre le diagnostic des pièces même lorsque celles-ci ne nécessitent pas l'installation de nouveaux dispositifs<span className="jira-issue">466</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">506</span></li>
                                    <li><span className="jira-issue">486</span></li>
                                    <li><span className="jira-issue">484</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 14/02/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.3.4)</strong>
                        <ul>
                            <li>Côté prescripteur, certains audits patient ne s'affichent pas<span className="jira-issue">478</span></li>
                            <li>Le nom de la mercuriale associée à un prescripteur ne s'affiche plus après mise à jour<span className="jira-issue">477</span></li>
                            <li>Correction de l'affichage de certains tarifs HT à quatre décimales<span className="jira-issue">475</span></li>
                            <li>L'import massif de patients échoue et renvoie une erreur 500<span className="jira-issue">473</span></li>
                            <li>Correction d'un défaut d'affectation d'une mercuriale à l'ensemble des clients concernés lors de sa mise à jour<span className="jira-issue">472</span></li>
                            <li>Correction d'un bug empêchant de correctement sélectionner un prescripteur principal<span className="jira-issue">464</span></li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.1.3)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">481</span></li>
                                    <li><span className="jira-issue">468</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 18/01/2023</h4>
                <ul>
                    <li><strong>Application Web (v1.3.3)</strong>
                        <ul>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">473</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 15/12/2022</h4>
                <ul>
                    <li><strong>Application Web (v1.3.2)</strong>
                        <ul>
                            <li>Possibilité d'ajouter des documents de type images à la fiche patient et correction d'un bug empêchant la fermeture de la fenêtre d'import<span className="jira-issue">462</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">461</span></li>
                                    <li><span className="jira-issue">459</span></li>
                                    <li><span className="jira-issue">366</span></li>
                                    <li><span className="jira-issue">364</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.1.2)</strong>
                        <ul>
                            <li>Désactivation du mode immersif Androïd qui a pour effet de masquer certains champs des formulaires avec le clavier de saisie<span className="jira-issue">463</span></li>
                            <li>Possibilité d'ajouter des documents de type image à la fiche patient<span className="jira-issue">462</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">467</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 22/10/2022</h4>
                <ul>
                    <li><strong>Application Tablette (v3.1.0)</strong>
                        <ul>
                            <li>Choix des images depuis l'appareil photo ou la galerie pour les maintenances<span className="jira-issue">460</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 19/10/2022</h4>
                <ul>
                    <li><strong>Application Web (v1.3.1)</strong>
                        <ul>
                            <li>Correction d'une régression empêchant de scinder les commandes<span className="jira-issue">458</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">442</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.0.1)</strong>
                        <ul>
                            <li>Correction d'une regression empêchant la création de nouveaux patients<span className="jira-issue">457</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Mise à jour du 18/10/2022</h4>
                <ul>
                    <li><strong>Application Web (v1.3.0)</strong>
                        <ul>
                            <li>Mise à niveau de certaines interfaces<span className="jira-issue">410</span></li>
                            <li>Amélioration de la pertinence des résultats dans le moteur de recherche des produits de Mercuriales<span className="jira-issue">375</span></li>
                            <li>Le PSDM peut afficher la liste des patients associés à un prescripteur<span className="jira-issue">377</span></li>
                            <li>Gestion de la suppression des compte-rendus de maintenances<span className="jira-issue">347</span></li>
                            <li>Amélioration des performances sur la récupération de données (compression de flux)<span className="jira-issue">329</span></li>
                            <li>Le résultat du moteur de recherche n'affiche pas la bonne déclinaison<span className="jira-issue">439</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">513</span></li>
                                    <li><span className="jira-issue">455</span></li>
                                    <li><span className="jira-issue">449</span></li>
                                    <li><span className="jira-issue">443</span></li>
                                    <li><span className="jira-issue">424</span></li>
                                    <li><span className="jira-issue">407</span></li>
                                    <li><span className="jira-issue">402</span></li>
                                    <li><span className="jira-issue">357</span></li>
                                    <li><span className="jira-issue">328</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><strong>Application Tablette (v3.0.0)</strong>
                        <ul>
                            <li>Amélioration des interfaces et des indicateurs visuels dans l'audit MAD<span className="jira-issue">450</span></li>
                            <li>Permettre l'effacement des signatures dans les rapports d'interventions et ajout d'une case à cocher pour offrir une alternative aux patients ayant des difficultés à signer<span className="jira-issue">448</span></li>
                            <li>Permettre de choisir d'ajouter des images soit depuis l'appareil photo soit depuis la galerie de la tablette dans les audits MAD et les interventions<span className="jira-issue">437</span></li>
                            <li>Les photos prises avec la tablette sont désormais aussi enregistrées automatiquement dans la galerie<span className="jira-issue">436</span></li>
                            <li>Sécurisation d'une sortie inopinée de l'application tablette par utilisation du bouton retour Androïd<span className="jira-issue">435</span></li>
                            <li>Mise à niveau de certaines interfaces<span className="jira-issue">410</span></li>
                            <li>Ajout d'un éditeur de croquis pour les audits MAD<span className="jira-issue">403</span></li>
                            <li>Permettre l'effacement des signatures dans l'audit à domicile<span className="jira-issue">401</span></li>
                            <li>Il est désormais possible de prendre jusqu'à 4 photos par pièce de vie dans les audits MAD<span className="jira-issue">385</span></li>
                            <li>Les données d'un audit MAD en cours peuvent être récupérées en cas de fermeture inopinée de l'application<span className="jira-issue">384</span></li>
                            <li>Le PSDM peut afficher la liste des patients associés à un prescripteur<span className="jira-issue">377</span></li>
                            <li>Gestion de la suppression des compte-rendus de maintenances<span className="jira-issue">347</span></li>
                            <li>Evolutions et correctifs mineurs
                                <ul>
                                    <li><span className="jira-issue">452</span></li>
                                    <li><span className="jira-issue">451</span></li>
                                    <li><span className="jira-issue">449</span></li>
                                    <li><span className="jira-issue">446</span></li>
                                    <li><span className="jira-issue">440</span></li>
                                    <li><span className="jira-issue">407</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 1.2.0 (03/09/2022)</h4>
                <ul>
                    <li>Mise à niveau du serveur et prise en charge des dernières versions Android</li>
                    <ul>
                        <span className="jira-issue e4bizz">39</span>
                        <span className="jira-issue e4bizz">38</span>
                        <span className="jira-issue e4bizz">37</span>
                        <span className="jira-issue e4bizz">36</span>
                        <span className="jira-issue e4bizz">35</span>
                        <span className="jira-issue e4bizz">30</span>
                        <span className="jira-issue e4bizz">28</span>
                        <span className="jira-issue e4bizz">26</span>
                    </ul>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">430</span></li>
                            <li><span className="jira-issue">428</span></li>
                            <li><span className="jira-issue">427</span></li>
                            <li><span className="jira-issue">426</span></li>
                            <li><span className="jira-issue">422</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 1.1.1 (17/08/2022)</h4>
                <ul>
                    <li>Correction d'un erreur empêchant le bon enregistrement du fichier PDF des maintenances depuis la tablette<span className="jira-issue">425</span></li>
                </ul>
                <h4>Version 1.1.0 (10/08/2022)</h4>
                <ul>
                    <li>Prérequis à l'implémentation de nouvelles fonctionnalités<span className="jira-issue">415</span></li>
                    <li>Correction d'un affichage de suivi de synchronisation des mercuriales avec MUST<span className="jira-issue">420</span></li>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">423</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 1.0.1 (21/07/2022)</h4>
                <ul>
                    <li>Correction d'un bug relatif aux quantités sur les déclinaisons de produits dans les paniers de commandes<span className="jira-issue">411</span></li>
                    <li>La liste des prescripteurs et des commandes est à nouveau disponible aux collaborateurs<span className="jira-issue">409</span></li>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">412</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 1.0.0 (13/07/2022)</h4>
                <ul>
                    <li>Refonte majeure de la gestion des mercuriales et des commandes pour permettre :<span className="jira-issue">397</span>
                        <ul>
                            <li>La modification des tarifs des produits de la mercuriale à la ligne</li>
                            <li>Le traitement des commandes en reliquat sur des mercuriales mise à jour ou expirées</li>
                        </ul>
                    </li>
                    <li>Comme pour les mercuriales, les patients peuvent désormais être importés à partir d'un modèle Excel<span className="jira-issue">369
                    </span></li>
                    <li>Optimisations des performances</li>
                    <ul>
                        <li>Récupération de données de mercuriales<span className="jira-issue">331</span><span className="jira-issue">333</span></li>
                        <li>Récupération de données prescripteurs<span className="jira-issue">322</span></li>
                        <li>Récupération de données patients<span className="jira-issue">321</span></li>
                    </ul>
                    <li>Ajout d'une case à cocher pour offrir une alternative aux patients ayant des difficultés à signer les audits<span className="jira-issue">383</span></li>
                    <li>Fiche patient/prescripteur: Les noms de villes sont désormais suggérés à partir du code postal saisi<span className="jira-issue">335</span><span className="jira-issue">379</span></li>
                    <li>Refonte du formulaire de création d'un prescripteur<span className="jira-issue">378</span></li>
                    <li>Fiche patient/prescripteur: Ajout d'un champ complément d'adresse<span className="jira-issue">341</span></li>
                    <li>Fiche patient: Possibilité de saisir un commentaire privé<span className="jira-issue">342</span></li>
                    <li>Refonte de la gestion des visuels produits (banque d'images)<span className="jira-issue">359</span></li>
                    <li>Meilleure prise en charge de la vérification des adresses mail<span className="jira-issue">358</span></li>
                    <li>Ajout des informations client dans les PDF de commandes<span className="jira-issue">353</span></li>
                    <li>Les dates de première installation et de prescription ne sont plus obligatoires à la saisie d'un dispositif patient<span className="jira-issue">350</span></li>
                    <li>Possibilité de supprimer une maintenance réalisée sur un dispositif<span className="jira-issue">311</span></li>
                    <li>Gestion améliorée des pièces jointes en annexe des devis<span className="jira-issue">289</span></li>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">405</span></li>
                            <li><span className="jira-issue">404</span></li>
                            <li><span className="jira-issue">400</span></li>
                            <li><span className="jira-issue">394</span></li>
                            <li><span className="jira-issue">393</span></li>
                            <li><span className="jira-issue">390</span></li>
                            <li><span className="jira-issue">389</span></li>
                            <li><span className="jira-issue">380</span></li>
                            <li><span className="jira-issue">354</span></li>
                            <li><span className="jira-issue">348</span></li>
                            <li><span className="jira-issue">344</span></li>
                            <li><span className="jira-issue">343</span></li>
                            <li><span className="jira-issue">336</span></li>
                            <li><span className="jira-issue">334</span></li>
                            <li><span className="jira-issue">325</span></li>
                            <li><span className="jira-issue">324</span></li>
                            <li><span className="jira-issue">323</span></li>
                            <li><span className="jira-issue">320</span></li>
                            <li><span className="jira-issue">318</span></li>
                            <li><span className="jira-issue">175</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.10.1 (14/01/2022)</h4>
                <ul>
                    <li>Afficher les visuels de déclinaisons produit si disponibles<span className="jira-issue">327</span></li>
                    <li>Correction d'une régression sur l'affichage de certains montants à plus de deux décimales<span className="jira-issue">295</span></li>
                    <li>Correction d'une régression sur règle d'arrondi de TVA sur les commandes<span className="jira-issue">270</span></li>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">326</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.10.0 (30/12/2021)</h4>
                <ul>
                    <li>Gestion des maintenances sur les dispositifs MAD<span className="jira-issue">285</span><span className="jira-issue">292</span>
                        <ul>
                            <li>Possibilité d'affecter un numéro interne et un numéro de série par dispositif</li>
                            <li>Possibilité de définir des points de contrôle pour chaque type de dispositif</li>
                            <li>Possibilité d'affecter un type de dispositif à plusieurs pièces d'audit</li>
                            <li>Possibilité d'éditer un rapport de maintenance PDF</li>
                        </ul>
                    </li>
                    <li>Edition de compte-rendus d'intervention au domicile<span className="jira-issue">264</span>
                        <ul>
                            <li>Possibilité d'éditer un rapport PDF d'installation, de reprise ou d'échange pour les dispositifs MAD</li>
                            <li>Possibilité d'envoyer le rapport par courriel</li>
                        </ul>
                    </li>
                    <li>Le PDF sur les nouvelles commandes ou les commandes en reliquat est désormais téléchargeable par le client<span className="jira-issue">279</span></li>
                    <li>La liste des prescripteurs a été corrigée pour l'envoi ou le renvoi d'un mail d'audit<span className="jira-issue">294</span></li>
                    <li>Amélioration du processus d'authentification unifiée pour l'application tablette
                        <ul>
                            <li><span className="jira-issue">300</span></li>
                            <li><span className="jira-issue">299</span></li>
                            <li><span className="jira-issue">298</span></li>
                            <li><span className="jira-issue">297</span></li>
                        </ul>
                    </li>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">296</span></li>
                            <li><span className="jira-issue">286</span></li>
                            <li><span className="jira-issue">284</span></li>
                            <li><span className="jira-issue">283</span></li>
                            <li><span className="jira-issue">268</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.9.1 (17/11/2021)</h4>
                <ul>
                    <li>Evolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">291</span></li>
                            <li><span className="jira-issue">287</span></li>
                            <li><span className="jira-issue">276</span></li>
                            <li><span className="jira-issue">275</span></li>
                            <li><span className="jira-issue">270</span></li>
                            <li><span className="jira-issue">266</span></li>
                            <li><span className="jira-issue">265</span></li>
                            <li><span className="jira-issue">261</span></li>
                            <li><span className="jira-issue">255</span></li>
                            <li><span className="jira-issue">249</span></li>
                            <li><span className="jira-issue">241</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.9.0 (04/10/2021)</h4>
                <ul>
                    <li>Interfaçage avec ORTHOP MUST G5<span className="jira-issue">232</span>
                        <ul>
                            <li>Possibilité de lier les comptes clients</li>
                            <li>Possibilité de lier les mercuriales</li>
                            <li>Possibilité d'envoyer automatiquement les commandes pour intégration dans MUST G5</li>
                        </ul>
                    </li>
                    <li>Correction d'une erreur empêchant la saisie de codes postaux valides<span className="jira-issue">283</span></li>
                    <li>Autres évolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">282</span></li>
                            <li><span className="jira-issue">281</span></li>
                            <li><span className="jira-issue">278</span></li>
                            <li><span className="jira-issue">277</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.8.4 (28/09/2021)</h4>
                <ul>
                    <li>Correction d'une erreur "not found" sur l'affichage d'un produit à partir du moteur de recherche côté client
                        <ul>
                            <li><span className="jira-issue">272</span></li>
                        </ul>
                    </li>
                </ul>
                <ul>
                    <li>Autres évolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">277</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.8.3 (29/07/2021)</h4>
                <ul>
                    <li>Correction d'un problème d'affichage des images par défaut dans la configuration des audits<span className="jira-issue">269</span></li>
                    <li>Autres évolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">253</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.8.2 (22/07/2021)</h4>
                <ul>
                    <li>Correction d'un problème de renvoi de mail pour les audits créés avant l'ajout du CERFA<span className="jira-issue">259</span></li>
                </ul>
                <h4>Version 0.8.1 (21/07/2021)</h4>
                <ul>
                    <li>Autres évolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">258</span></li>
                            <li><span className="jira-issue">257</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.8.0 (21/07/2021)</h4>
                <ul>
                    <li>Audits
                        <ul>
                            <li>Possibilité d'éditer un CERFA spécimen pour les matériels soumis à prescription obligatoire<span className="jira-issue">226</span><span className="jira-issue">245</span>
                            </li>
                            <li>Possibilité de supprimer un audit depuis le web ou la tablette<span className="jira-issue">242</span>
                            </li>
                            <li>Permettre le renvoi des audits par mail<span className="jira-issue">243</span>
                            </li>
                        </ul>
                    </li>
                    <li>Commandes
                        <ul>
                            <li>Correction d'une erreur empêchant l'affichage de l'adresse du client sur le PDF dans certains cas<span className="jira-issue">143</span></li>
                            <li>Seules les commandes qui n'ont pas fait l'objet d'un traitement par le PSDM (statut "Nouvelle") peuvent être supprimées par le prescripteur<span className="jira-issue">230</span></li>
                        </ul>
                    </li>
                    <li>Mercuriales
                        <ul>
                            <li>Correction d'une possible erreur de date de début lors de la mise à jour de mercuriales anticipées<span className="jira-issue">160</span></li>
                        </ul>
                    </li>
                    <li>Patients
                        <ul>
                            <li>Ajout d'un espace "Factures"<span className="jira-issue">135</span></li>
                        </ul>
                    </li>
                    <li>Configuration
                        <ul>
                            <li>Réorganisation du menu des audits<span className="jira-issue">237</span></li>
                            <li>Notification explicite des messages automatiques dans les entêtes de courriels<span className="jira-issue">232</span></li>
                            <li>Correction d'un défaut d'affichage des alertes renouvellement côté prescripteur<span className="jira-issue">153</span></li>
                        </ul>
                    </li>
                    <li>Autres évolutions et correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">254</span></li>
                            <li><span className="jira-issue">252</span></li>
                            <li><span className="jira-issue">251</span></li>
                            <li><span className="jira-issue">248</span></li>
                            <li><span className="jira-issue">247</span></li>
                            <li><span className="jira-issue">244</span></li>
                            <li><span className="jira-issue">240</span></li>
                            <li><span className="jira-issue">238</span></li>
                            <li><span className="jira-issue">236</span></li>
                            <li><span className="jira-issue">234</span></li>
                            <li><span className="jira-issue">233</span></li>
                            <li><span className="jira-issue">231</span></li>
                            <li><span className="jira-issue">229</span></li>
                            <li><span className="jira-issue">225</span></li>
                            <li><span className="jira-issue">224</span></li>
                            <li><span className="jira-issue">223</span></li>
                            <li><span className="jira-issue">220</span></li>
                            <li><span className="jira-issue">213</span></li>
                            <li><span className="jira-issue">206</span></li>
                            <li><span className="jira-issue">132</span></li>
                        </ul>
                    </li>
                </ul>

                <h4>Version 0.7.4 (21/05/2021)</h4>
                <ul>
                    <li>Mise à jour des textes de présentation relatifs aux pièces d'audit<span className="jira-issue">218</span>
                    </li>
                </ul>

                <h4>Version 0.7.3 (14/05/2021)</h4>
                <ul>
                    <li>Mercuriales
                        <ul>
                            <li>Réorganisation de l'interface pour l'administrateur PSDM<span className="jira-issue">195</span>
                            </li>
                            <li>Ajout d'un indicateur visuel dans la liste des prescripteurs lorsqu'une mercuriale affectée a
                                expiré<span className="jira-issue">194</span>
                            </li>
                            <li>Correction de la gestion du taux de TVA en fonction du format trouvé dans le fichier
                                d'import<span className="jira-issue">151</span>
                            </li>
                            <li>Correction de la gestion du panier en attente en cas de changement de mercuriale<span className="jira-issue">197</span>
                            </li>
                            <li>Au moment de valider sa commande, un message explicite indique au prescripteur le solde manquant
                                en cas de minimum de commande imposé<span className="jira-issue">200</span>
                            </li>
                        </ul>
                    </li>
                    <li>Audits
                        <ul>
                            <li>Refonte de l'interface<span className="jira-issue">205</span><span className="jira-issue">216</span>
                            </li>
                            <li>Les audits sont désormais modifiables après l'étape de prévisualisation<span className="jira-issue">203</span>
                            </li>
                            <li>Reprise du PDF<span className="jira-issue">204</span>
                            </li>
                            <li>Les commentaires internes sont désormais consultables depuis la tablette et l'interface en
                                ligne<span className="jira-issue">201</span>
                            </li>
                            <li>La déclaration du prescripteur et de l'aidant est désormais facultative<span className="jira-issue">202</span>
                            </li>
                        </ul>
                    </li>
                    <li>Correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">133</span></li>
                            <li><span className="jira-issue">171</span></li>
                            <li><span className="jira-issue">197</span></li>
                            <li><span className="jira-issue">198</span></li>
                            <li><span className="jira-issue">199</span></li>
                            <li><span className="jira-issue">207</span></li>
                            <li><span className="jira-issue">208</span></li>
                            <li><span className="jira-issue">209</span></li>
                            <li><span className="jira-issue">211</span></li>
                            <li><span className="jira-issue">212</span></li>
                            <li><span className="jira-issue">214</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.6.0 (25/03/2021)</h4>
                <ul>
                    <li>Mercuriales
                        <ul>
                            <li>Refonte du moteur de recherche et de l'affichage des produits<span className="jira-issue">173</span>
                            </li>
                            <li>Ajout de la gestion des produits favoris<span className="jira-issue">140</span>
                            </li>
                        </ul>
                    </li>
                    <li>Correctifs mineurs
                        <ul>
                            <li><span className="jira-issue">188</span></li>
                            <li><span className="jira-issue">189</span></li>
                            <li><span className="jira-issue">190</span></li>
                        </ul>
                    </li>
                </ul>
                <h4>Version 0.5.0 (16/02/2021)</h4>
                <ul>
                    <li>Mise à niveau du module d'importation de mercuriales<span className="jira-issue">166</span></li>
                    <li>Le cas échéant, la mercuriale associée s'affiche dans le tableau récapitulatif des
                        prescripteurs<span className="jira-issue">159</span>
                    </li>
                    <li>En cas de mise à jour d'une mercuriale, la réaffectation au(x) client(s) associé(s) se fait
                        automatiquement<span className="jira-issue">157</span></li>
                </ul>
                <h4>Version 0.4.3 (14/12/2020)</h4>
                <ul>
                    <li>Le renouvellement de location de matériel médical est désormais possible jusqu'à 12 mois</li>
                    <li>Correction de problèmes d'import d'images de mercuriales avec certaines références produits</li>
                    <li>Correction d'incohérences de couleurs sur les dates d'expiration de certaines locations</li>
                </ul>
                <h4>Version 0.4.2 (23/11/2020)</h4>
                <ul>
                    <li>Correction de l'affichage des dates dans la gestion des équipements de la fiche patient</li>
                </ul>
                <h4>Version 0.4.1 (09/11/2020)</h4>
                <ul>
                    <li>Tests complémentaires sur les données saisies</li>
                </ul>
                <h4>Version 0.4.0 (26/08/2020)</h4>
                <ul>
                    <li>Simplification des modalités de connexion (mémorisation d'identifiant, accès direct pour les clients)
                    </li>
                    <li>Consolidation de l'interface graphique entre prestataire et client</li>
                    <li>Ajout d'indicateurs visuels (codes couleurs) sur les statuts</li>
                    <li>Amélioration de l'affichage des tableaux et de la pagination</li>
                    <li>Ajout de fonctionalités de filtrage de résultats</li>
                </ul>
                <h4>Version 0.2.7 (10/06/2020)</h4>
                <ul>
                    <li>Correctifs d'affichage mineurs</li>
                </ul>
                <h4>Version 0.2.6 (08/06/2020)</h4>
                <ul>
                    <li>Diffusion des notes de version</li>
                </ul>
                <h4>Version 0.2.5 (08/06/2020)</h4>
                <ul>
                    <li>Amélioration du processus d'importation des mercuriales et du système de notification en cas d'erreur
                    </li>
                </ul>
                <h4>Version 0.2.4 (29/05/2020)</h4>
                <ul>
                    <li>Amélioration de la gestion de la session permettant de rafraichir la page sans être déconnecté de
                        l'application</li>
                    <li>Correctifs mineurs</li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Changelog));
