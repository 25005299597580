import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import Icon from "../sub/Icon";
import { updateMercurialEndDate } from "../../actions/mercurials/mercurials";
import DateUtil from "../../util/DateUtil";

class MercurialFileModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nowDate:
                new Date().getFullYear() +
                "-" +
                ("0" + (new Date().getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + new Date().getDate()).slice(-2),
            disabled: false,
            file: null,
            name: this.props.mercurial ? this.props.mercurial.name : "",
            startDate: this.props.mercurial
                ? this.props.mercurial.start_date
                : new Date(),
            endDate: this.props.mercurial ? this.props.mercurial.end_date : "",
            nameError: null,
            dateError: null,
            endDateError: null,
            formVisibility: true,
            progressBarVisibility: false,
        };
    }

    close() {
        this.props.closeModal();
    }

    onDropFile(file) {
        this.setState({ file: file });
    }

    onNameChange(e) {
        var name = e.target.value;

        for (let mercurial of this.props.mercurials) {
            if (mercurial.name === name) {
                this.setState({
                    name: name,
                    nameError: <FormattedMessage id="Mercurials.Already.Imported" />,
                });
                return;
            }
        }

        this.setState({ name: name, nameError: null });
    }

    onStartDateChange(e) {
        let startDateValue = e.target.value;

        var error = null;
        var endError = null;
        if (
            this.state.endDate &&
            startDateValue &&
            startDateValue > this.state.endDate
        ) {
            error = <FormattedMessage id="StartDate.Superior.EndDate" />;
        } else if (this.state.endDate && this.state.endDate < this.state.nowDate) {
            endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
        }

        this.setState({
            startDate: startDateValue,
            dateError: error,
            endDateError: endError,
        });
    }

    onEndDateChange(e) {
        let endDateValue = e.target.value;

        var error = null;
        var endError = null;
        if (
            this.state.startDate &&
            endDateValue &&
            this.state.startDate > endDateValue
        ) {
            error = <FormattedMessage id="StartDate.Superior.EndDate" />;
        } else if (endDateValue < this.state.nowDate) {
            endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
        }

        this.setState({
            endDate: endDateValue,
            dateError: error,
            endDateError: endError,
        });
    }

    onComplete() {
        if (this.props.mercurial) {
            const data = {
                _id: this.props.mercurial._id,
                end_date: this.state.endDate,
            };
      
            this.props.onUpdateMercurialEndDate(data, () => this.props.closeModal());
        } else {
            this.setState({
                disabled: true,
                formVisibility: false,
                progressBarVisibility: true,
            });
      
            this.props.onComplete(
                this.state.name,
                this.state.file,
                this.state.startDate,
                this.state.endDate,
            );
        }
    }

    disableButton() {
        return (
            (!this.props.mercurial && !this.state.file) ||
            !this.state.name ||
            !this.state.startDate ||
            !this.state.endDate ||
            this.state.startDate > this.state.endDate ||
            this.state.endDate < this.state.nowDate ||
            this.state.disabled ||
            this.state.nameError
        );
    }

    render() {
        const isUpdate = this.props.mercurial;
        return (
            <Modal
                show={true}
                onHide={() => this.close()}
                backdrop={"static"}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                    <FormattedMessage id={isUpdate ? "Mercurial.Extend" : "Mercurials.Add.Some"} />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!isUpdate && <h4 className="mb-5 w-100 text-center ">
                        <FormattedMessage id="Step.1" />
                    </h4>}

                    {this.state.progressBarVisibility && <div
                        className={"text-center mb-5 "}
                    >
                        <Icon icon="gear" className="fa-spin text-success mb-3" size="3x" />
                        <div className="progress" style={{ height: "30px" }}>
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "100%" }}
                            >
                                <strong>
                                    <FormattedMessage id="Import.Mercurial.In.Progress" />
                                </strong>
                            </div>
                        </div>
                    </div>}

                    {this.state.formVisibility && <div>
                        <div className="form-group row">
                            <CustomLabel
                                label={this.props.intl.formatMessage({
                                    id: "Mercurials.Name.Of",
                                })}
                                htmlFor="fam"
                                labelClassName="col-sm-4"
                                required
                            />
                            <div id="fam" className="col-sm-8 pb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="50"
                                    id="file-name"
                                    disabled={isUpdate}
                                    onChange={(e) => !isUpdate && this.onNameChange(e)}
                                    value={this.state.name}
                                />
                                <div className="text-danger">
                                    <small>{this.state.nameError}</small>
                                </div>
                            </div>
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "Start.Date" })}
                                htmlFor="file-start-date"
                                labelClassName="col-sm-4"
                                required
                            />
                            <div className="col-sm-8 pb-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="file-start-date"
                                    disabled={isUpdate}
                                    onChange={(e) => !isUpdate && this.onStartDateChange(e)}
                                    value={DateUtil.toyyyyMMdd(this.state.startDate)}
                                />
                                <div className="text-danger">
                                    <small>{this.state.dateError}</small>
                                </div>
                            </div>
                            <CustomLabel
                                label={this.props.intl.formatMessage({ id: "End.Date" })}
                                htmlFor="file-end-date"
                                labelClassName="col-sm-4"
                                required
                            />
                            <div className="col-sm-8 pb-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="file-end-date"
                                    onChange={(e) => this.onEndDateChange(e)}
                                    value={DateUtil.toyyyyMMdd(this.state.endDate)}
                                    min={isUpdate ? DateUtil.toyyyyMMdd(this.props.mercurial.end_date) : DateUtil.toyyyyMMdd(this.state.startDate)}
                                />
                                <div className="text-danger">
                                    <small>{this.state.endDateError}</small>
                                </div>
                            </div>
                        </div>
                        {!isUpdate && <>
                            <CustomLabel
                                label={this.props.intl.formatMessage({
                                    id: "Import.File.Mercurial",
                                })}
                                htmlFor="name"
                                labelClassName="col-12"
                                required
                            />
                            <FileDropZone
                                onDropFile={(file) => this.onDropFile(file)}
                                acceptedExtensions={["xls", "xlsx"]}
                                multiple={false}
                            />

                            <div className="w-100 text-center mt-3">
                                <a
                                    role="button"
                                    href="templates/template_mercurial.xlsx"
                                    download="template_mercurial.xlsx"
                                >
                                    <Icon icon="file-excel" className="text-blue mr-2" />

                                    <FormattedMessage id="Mercurials.Download.Template" />
                                </a>
                            </div>
                        </>}
                    </div>}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.close()}>
                        <FormattedMessage id="Cancel" />
                    </Button>
                    <Button
                        variant="info"
                        onClick={() => this.onComplete()}
                        disabled={this.disableButton()}
                    >
                        <FormattedMessage
                            id={isUpdate ? "Modify" : !this.state.progressBarVisibility ? "Step.2" : "Plz.Wait"}
                        />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mercurials: state.mercurials,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateMercurialEndDate: (data, successCallback) =>
            dispatch(updateMercurialEndDate(data, successCallback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MercurialFileModal));
