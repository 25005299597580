import APIUrl from "../../APIUrl";
import axios from 'axios';
import {getProductsAdmin} from "../products/products";
import {getCart} from "../cart/cart";
import {getNotifs} from "../notifications/notifications";
import {getOrdersAdmin} from "../orders/orders";
import {getProductsByMercurialIdAdmin} from "../products/products";
import { getClientsAdmin } from "../clients/clients";

export const GET_MERCURIAL = "GET_MERCURIAL";
export const GET_MERCURIALS_ADMIN = "GET_MERCURIALS_ADMIN";

function getMercurialAction(mercurial) { return { type: GET_MERCURIAL, mercurial: mercurial } };
function getMercurialsAdminAction(mercurials) { return { type: GET_MERCURIALS_ADMIN, mercurials: mercurials } };

export const getMercurialsAdmin = function (successCallback) {
    return function(dispatch){
        return axios.get(APIUrl.getMercurialsAdmin)
            .then(function(response){
                dispatch(getMercurialsAdminAction(response.data));
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const getMercurial = function (successCallback) {
    return function (dispatch) {
        return axios.get(APIUrl.getMercurial)
            .then(function (response) {
                dispatch(getMercurialAction(response.data));
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const addMercurial = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addMercurial, data)
            .then(function(response){
                dispatch(getMercurialsAdmin());
                dispatch(getProductsAdmin());

                return successCallback(response.data);
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const updateMercurial = function(data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.updateMercurial, data)
            .then(function(response){
                if(successCallback) successCallback();
                dispatch(getMercurialsAdmin());
                dispatch(getClientsAdmin());
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const updateMercurialStatus = function(data){
    return function(dispatch){
        return axios.post(APIUrl.updateMercurialStatus, data)
            .then(function(response){
                dispatch(getMercurialsAdmin());
                dispatch(getClientsAdmin());
                // dispatch(getProductsAdmin());
                // dispatch(getCart());
                // dispatch(getNotifs());
                // dispatch(getOrdersAdmin());

                // return successCallback(response.data);
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const updateMercurialEndDate = function (data, successCallback) {
    return function (dispatch) {
      return axios
        .post(APIUrl.updateMercurialEndDate, data)
        .then(function (response) {
          dispatch(getMercurialsAdmin());
  
          if (successCallback) return successCallback(response.data);
        })
        .catch(function (err) {
          throw err;
        });
    };
  };

export const syncWithCrm = function(data){
    return function(dispatch){
        return axios.post(APIUrl.syncWithCrm, data)
            .then(function(response){
                dispatch(getMercurialsAdmin());
                // dispatch(getProductsAdmin());
                // dispatch(getCart());
                // dispatch(getNotifs());
                // dispatch(getOrdersAdmin());

                // return successCallback(response.data);
            })
            .catch(function(err){
                throw err;
            });
    };
};

export const deleteMercurial = function(data){
    return function(dispatch){
        return axios.post(APIUrl.deleteMercurial, data)
            .then(function(response){
                dispatch(getMercurialsAdmin());
                dispatch(getProductsAdmin());
                dispatch(getCart());
                dispatch(getNotifs());
                dispatch(getOrdersAdmin());
                dispatch(getClientsAdmin());
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const addProductsImgs = function(mercurialId, data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addProductsImgs + mercurialId, data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(function(response){
                dispatch(getProductsByMercurialIdAdmin(mercurialId, true));
                return successCallback();
            })
            .catch(function(err){
                throw(err);
            });
    };
};

export const removeProductsImgs = function(mercurialId, data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.removeProductsImgs + mercurialId, data)
        .then(function(response){
            dispatch(getProductsByMercurialIdAdmin(mercurialId, true));
            
            return successCallback();
        })
        .catch(function(err){
            throw(err);
        });
    };
};

export const addFamilyImgs = function(mercurialId, data, successCallback){
    return function(dispatch){
        return axios.post(APIUrl.addFamilyImgs + mercurialId, data, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(function(response){
                return successCallback();
            })
            .catch(function(err){
                throw(err);
            });
    };
};