import React from 'react';
import { connect } from 'react-redux';

import Orders from "../../orders/Orders";
import Estimates from "../../estimates/Estimates";
import Patients from "../../patients/Patients";
import SplitHomeItem from './SplitHomeItem';
import OrdersAdmin from '../../orders/admin/OrdersAdmin';
import Prescribers from '../../prescribers/Prescribers';
import Interventions from '../../intervention/Interventions';

import Accessibilities from '../../../enums/MenuAccessibilities';
import Roles from '../../../enums/Roles';
import MercurialStatus from '../../../enums/MercurialStatus';
import { Alert } from 'react-bootstrap';
import Icon from '../../sub/Icon';
import { FormattedMessage } from "react-intl";

class SplitHome extends React.Component {
    getAccessibilityByRole(itemAccessibility) {
        switch(this.props.user.role) {
            case Roles.CLIENT:
                if (itemAccessibility === Accessibilities.CLIENT_ONLY || itemAccessibility === Accessibilities.COMMON) return true;
                break;
            case Roles.ADMIN:
            case Roles.SALES_REP:
                if (itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP || itemAccessibility === Accessibilities.COMMON) return true;
                break;
            default: 
                //console.log(this.props.user.role + " is an user role with no case");
        }
        return false;
    }

    isWithinNext30Days(dateString) {
        const inputDate = new Date(dateString);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(today.getDate() + 30);
    
        return inputDate >= today && inputDate <= thirtyDaysFromNow;
    }

    render() {
        const items = 
        [
            {link:"/home/orders", formattedMessageId:"Summary.Orders", accessibility:Accessibilities.CLIENT_ONLY, content: <Orders limit={5} />},
            {link:"/home/estimates", formattedMessageId:"Summary.Estimates", accessibility:Accessibilities.CLIENT_ONLY, content: <Estimates limit={5} />},
            
            {link:"/home/orders", formattedMessageId:"Summary.Orders.Admin", accessibility:Accessibilities.ADMIN_AND_SALES_REP, content: <OrdersAdmin limit={5} />},
            {link:"/home/estimates", formattedMessageId:"Summary.Estimates.Admin", accessibility:Accessibilities.ADMIN_AND_SALES_REP, content: <Estimates limit={5} />},
            
            this.props.user.client_type !== "patient" && {link:"/home/patients", formattedMessageId:"Summary.Patients.Monitoring", accessibility:Accessibilities.COMMON, content: <Patients limit={5} />},
            {link:"/home/clients", formattedMessageId:"Summary.Clients.Admin", accessibility:Accessibilities.ADMIN_AND_SALES_REP, content: <Prescribers limit={5} />},
            {link:"/home/interventions", formattedMessageId:"Interventions.History", accessibility:Accessibilities.COMMON, content: <Interventions limit={5} />},
        ];

        const itemNode = (items) => {
            return items.map((item,index) => { return  this.getAccessibilityByRole(item.accessibility) && (
                <div key={index} className="col-12 col-lg-6 max-width">
                    <SplitHomeItem
                        formattedMessageId={item.formattedMessageId}
                        content={item.content}
                        link={item.link}
                    />
                </div>
            )});
        }

        const {mercurials} = this.props;

        let hasMercurialToExtend = false;

        if (mercurials && mercurials.length !== 0) {
            for (const mercurial of mercurials) {
            const isOutDated = new Date(mercurial.end_date) < new Date();
            const isInactive = mercurial.status === MercurialStatus.INACTIVE;
        
                if (!isInactive && !isOutDated && this.isWithinNext30Days(mercurial.end_date)) {
                    hasMercurialToExtend = true;
                    break;
                }
            }
        }

        return (
            <div className="d-flex flex-wrap">
                {this.props.user.role !== Roles.CLIENT && hasMercurialToExtend && 
                    <div className="col-12">
                        <Alert variant="warning"><Icon icon="clock" /> <FormattedMessage id="Mercurial.Extend.Info" /></Alert>
                    </div>
                }
                
                {itemNode(items)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        mercurials: state.mercurials,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitHome);
