import Util from "./util/Util";
const rootUrl = Util.checkHttps(process.env.REACT_APP_BE_URL);
const loginUrl = Util.checkHttps(process.env.REACT_APP_LOGIN_BE_URL);

var APIUrl = {
    // User
    getUser: rootUrl + "/user",                                              // GET
    updateLang: rootUrl + "/user/lang/",                                        // GET {lang = ["en", "fr"]}
    checkIfUsernameExists: rootUrl + "/user/check/username/",                            // GET {username}
    checkIfEmailExists: rootUrl + "/user/check/email/",                                 // GET {email}
    checkIfUpdatedEmailExists: rootUrl + "/user/check/updatedemail/",                   // GET {updatedemail}
    checkIfEmailIsValid: loginUrl + "/validator/email/", // GET {email}
    updateUser: rootUrl + "/user/update",                                       // POST {updatedField, updatedValue}
    getAdmin: rootUrl + "/user/getadmin",                                     // GET {}
    addFavorite: rootUrl + "/user/favorite/add",//POST
    deleteFavorite: rootUrl + "/user/favorite/delete",//POST

    //Promo offer
    getPromoOperations: rootUrl + "/promo",                                  // GET
    getPromoOperationFile: rootUrl + "/promo/file/",

    // Rgpd
    createRgpd: rootUrl + "/rgpd/create",                                            // POST
    getRgpd: rootUrl + "/rgpd/",                                            // GET {}

    // Products
    getProducts: rootUrl + "/product",                                           // GET
    getProductImg: rootUrl + "/product/img/",                                      // GET {mercurialId, ref}
    getFamilyImg: rootUrl + "/product/fam/img/",                                  // GET {mercurialId, fam}
    getManyProducts: rootUrl + "/product/many",                                      // POST {ids}
    getListProducts: rootUrl + "/product/list",

    // Cart
    getCart: rootUrl + "/cart",                                              // GET
    addProductToCart: rootUrl + "/cart/add",                                          // POST {id_product, quantity}
    updateUrgent: rootUrl + "/cart/urgent",                                       // POST {cartId, urgent}
    increaseQuantity: rootUrl + "/cart/inc",                                          // POST {cartId}
    decreaseQuantity: rootUrl + "/cart/dec",                                          // POST {cartId}
    deleteCartById: rootUrl + "/cart/delete",                                       // POST {cartId}
    deleteAll: rootUrl + "/cart/delete/all",                                   // POST

    // Order
    addOrder: rootUrl + "/order/add",                                         // POST {comment}
    getOrders: rootUrl + "/order",                                             // GET
    deleteOrder: rootUrl + "/order/delete",                                      // POST {orderId}
    duplicate: rootUrl + "/order/duplicate",                                   // POST {orderId}
    // validate: rootUrl + "/order/validate",                                    // POST {orderId}

    // PDFs
    toPDF: rootUrl + "/pdf",                                               // POST {html, css=[], header, footer}

    // Notifications
    getNotifs: rootUrl + "/notifs",                                            // GET
    deleteNotif: rootUrl + "/notifs/delete",                                     // POST {notifId}

    // Orders settings
    getOrdersSettings: rootUrl + "/settings/orders",                                   // GET

    // Requests
    getRequests: rootUrl + "/requests",                                          // GET
    addNewRequest: rootUrl + "/requests/add",                                      // POST {material_type_id, method, precisions}
    getRequestFile: rootUrl + "/requests/file/",

    // Mercurial
    getMercurial: rootUrl + "/mercurial",

    // Checkpoints
    getCheckpoints: rootUrl + "/checkpoint", // GET

    getMaintenances: rootUrl + "/maintenance", // GET
    getMaintenancesPDF: rootUrl + "/maintenance/file/",

    // Interventions
    getInterventions: rootUrl + "/intervention", // GET
    getInterventionsPDF: rootUrl + "/intervention/file/",

    // Collaborator types
    getCollaboratorTypes: rootUrl + "/collaboratortype",

    // Contact
    contact: rootUrl + "/contacts",

    // General settings
    getGeneralSettings: rootUrl + "/generalsettings",                                // GET
    getGeneralSettingsLogo: rootUrl + "/generalsettings/logo/",                          // URL {generalSettingsId} - GET

    // Clients
    getClients: rootUrl + "/client",

    // Companies
    checkIfCompanyUrlExists: rootUrl + "/company/check/url/",                         // GET {url}
    getCompany: rootUrl + "/company",                                         // GET

    // Audit DM
    getAuditDMs: rootUrl + "/settings/dm",                                 // GET
    getAuditDMImg: rootUrl + "/settings/dm/img/",                                  // GET URL {auditDMId, roomId}

    // Audit Room
    getAuditRoomImg: rootUrl + "/settings/room/img/",                                  // GET URL {auditRoomId, roomId}

    // Desk Category
    getDeskCategoryImg: rootUrl + "/settings/deskcategory/img/", // GET URL {catId, roomId}

    // Desk Product
    getDeskProductImg: rootUrl + "/settings/deskproduct/img/",

    // Type Prescribers
    getPrescriberTypes: rootUrl + "/prescribertype",                                    // GET
    addPrescriberType: rootUrl + "/prescribertype/add",                                // POST
    updatePrescriberType: rootUrl + "/prescribertype/update",                          // POST
    deletePrescriberType: rootUrl + "/prescribertype/delete",                           // POST

    //Audit Settings
    getAuditSettings: rootUrl + "/auditsettings",                                           // GET
    getAuditSettingsLogo: rootUrl + "/auditsettings/logo/",                                      // URL {generalSettingsId} - GET

    //Audit Desk Settings
    getAuditDeskSettings: rootUrl + "/auditdesksettings", // GET

    // Patients
    getPatients: rootUrl + "/patient",                                          // GET
    updPatient: rootUrl + "/patient/upd",                                        // POST

    // Audits
    getAudits: rootUrl + "/audits",                                  // GET
    getAuditsPDF: rootUrl + "/audits/file/", // GET URL
    getCheckAuditsPDF: rootUrl + "/audits/check/file/",

    // Audits Desk
    getDeskAudits: rootUrl + "/desk/audit", // GET

    // Documents Database
    getDocumentsDbCategoriesTree: rootUrl + "/documentscategories/tree",
    getDocumentsDbCategoriesDescendants: rootUrl + "/documentscategories/descendants/",
    getDocumentsDbFilesByCategoryId: rootUrl + "/documentsdb/category",     // POST {category_id}
    getDocumentsDbFile: rootUrl + "/documentsdb/file/",                        // POST {documentId}

    // ======================================================================
    // ======================== ADMIN AND SALES_REP==========================
    // ======================================================================

    // Mercurials
    getMercurialsAdmin: rootUrl + "/admin/mercurial",                                 // GET
    addMercurial: rootUrl + "/admin/mercurial/add",
    updateMercurial: rootUrl + "/admin/mercurial/update",                           // POST {name, fileName, data, startDate, endDate}
    syncWithCrm: rootUrl + "/admin/mercurial/crmsync",
    updateMercurialStatus: rootUrl + "/admin/mercurial/updatestatus",                          // POST {mercurialId, data}
    updateMercurialEndDate: rootUrl + "/admin/mercurial/update/endDate", // POST {_id, end_date}
    deleteMercurial: rootUrl + "/admin/mercurial/delete",                          // POST {mercurialId}
    addProductsImgs: rootUrl + "/admin/mercurial/imgs/products/",                  // URL {mercuriaId} - POST {imgs}
    removeProductsImgs: rootUrl + "/admin/mercurial/imgs/products/", // URL {mercuriaId} - POST {imgs}
    addFamilyImgs: rootUrl + "/admin/mercurial/imgs/families/",                  // URL {mercuriaId} - POST {imgs}
    getMercImgOverview: rootUrl + "/admin/mercurial/img/overview/",                   // GET {mercurialId}

    // Orders
    getOrdersAdmin: rootUrl + "/admin/order",                                     // GET
    addOrderAdmin: rootUrl + "/admin/order/add",                                 // POST {comment, clientId}
    updateAdmin: rootUrl + "/admin/order/update",
    deleteOrderAdmin: rootUrl + "/admin/order/delete",                              // POST {orderId}
    splitOrderAdmin: rootUrl + "/admin/order/split",                               // POST {orderId, productsAvailable, productsUnavailable}
    duplicateAdmin: rootUrl + "/admin/order/duplicate",

    // Clients
    getClientsAdmin: rootUrl + "/admin/client",                                           // GET
    getClient: rootUrl + "/admin/client/id",                                        // POST {clientId}
    addClient: rootUrl + "/admin/client/add",                                       // POST
    updClient: rootUrl + "/admin/client/update",

    // Products
    getProductsAdmin: rootUrl + "/admin/product",                                   // GET
    getProductsByMercurialIdAdmin: rootUrl + "/admin/product/mercurial/",
    getListProductsAdmin: rootUrl + "/admin/product/list",
    updateProductAdmin: rootUrl + "/admin/product/update",
    getProductsByMercurialIdInactive: rootUrl + "/admin/product/mercurial/inactive/",    // POST {productId, updatedField, updatedValue}

    // Cart
    addProductToCartAdmin: rootUrl + "/admin/cart/add",

    //Company 
    deleteCompanyNotifAdmin: rootUrl + "/admin/company/notif/delete",

    // Requests
    getAllRequests: rootUrl + "/admin/requests",
    addNewRequestAdmin: rootUrl + "/admin/requests/add",                                // GET
    updateRequest: rootUrl + "/admin/requests/update",                         // POST {requestId, updatedField, updatedValue}
    addRequestFiles: rootUrl + "/admin/requests/files/",                         // URL {requestId} - POST {files}
    deleteRequest: rootUrl + "/admin/requests/delete",                        // DELETE
    deleteRequestFile: rootUrl + "/admin/requests/file/delete",

    // Maintenances   
    addMaintenance: rootUrl + "/admin/maintenance/add",                             // POST {equipmentId, checkpoints}
    deleteMaintenance: rootUrl + "/admin/maintenance/delete", //POST

    // Interventions
    getInterventionsAdmin: rootUrl + "/admin/intervention", // GET
    deleteIntervention: rootUrl + "/admin/intervention/delete",


    // Settings order
    getOrdersSettingsAdmin: rootUrl + "/admin/settings/orders",                   // GET
    updateOrderSettingAdmin: rootUrl + "/admin/settings/orders/update",            // POST {orderSettingId, updatedField, updatedValue}
    updateShippingCostsSetting: rootUrl + "/admin/settings/orders/shipping/update",   // POST {shippingCosts}

    // Patients
    getPatientsAdmin: rootUrl + "/admin/patient",                                       // GET
    addPatient: rootUrl + "/admin/patient/add",                                       	// POST
    importPatients: rootUrl + "/admin/patient/import",
    getPatientsTemplate: rootUrl + "/admin/patient/template", // GET
    deletePatient: rootUrl + "/admin/patient/remove",                                  	// POST
    addPatientDocument: rootUrl + "/admin/patient/document/file/add/",                	// POST {patientId}
    deletePatientDocument: rootUrl + "/admin/patient/document/file/delete/",          	// GET  {patientId, documentId}
    getPatientDocumentFile: rootUrl + "/admin/patient/document/file/",                	// GET  {patientId, documentId}
    getPatientEquipmentsReminded: rootUrl + "/admin/patient/equipment/reminder/renewal", // GET
    
    // Rooms audit
    addAuditRoom: rootUrl + "/admin/settings/room/add",                           // POST {name, default_text}
    getAuditRooms: rootUrl + "/admin/settings/room",                               // GET
    getAuditRoom: rootUrl + "/admin/settings/room/check/",                        // GET {name}
    updateAuditRoom: rootUrl + "/admin/settings/room/update",                        // POST {auditRoomId, updatedField, updatedValue}
    deleteAuditRoom: rootUrl + "/admin/settings/room/delete",                        // POST {auditRoomId}
    addAuditRoomFile: rootUrl + "/admin/settings/room/file/",                         // URL {auditDMId, roomId} - POST {file}

    // Audit DM
    addAuditDM: rootUrl + "/admin/settings/dm/add",                             // POST {name, room, text, type, refundable}
    updateAuditDM: rootUrl + "/admin/settings/dm/update",                          // POST {auditDMId, updatedField, updatedValue}
    deleteAuditDM: rootUrl + "/admin/settings/dm/delete",                          // POST {auditDMId}
    getAuditDMByName: rootUrl + "/admin/settings/dm/check/",                   // GET {name, room_id}
    addAuditDMFile: rootUrl + "/admin/settings/dm/file/",                           // URL {auditDMId, roomId} - POST {file}
    sortCheckpoint: rootUrl + "/admin/settings/dm/checkpoint/sort",        // POST
    addCheckpointToDM: rootUrl + "/admin/settings/dm/checkpoint/add",                  // POST {materialTypeId, checkpoint}
    updateCheckpoint: rootUrl + "/admin/settings/dm/checkpoint/update",                  // POST {checkpoint}
    deleteCheckpointFromDM: rootUrl + "/admin/settings/dm/checkpoint/delete",            // POST {materialTypeId, checkpoint}
    addRoomToDM: rootUrl + "/admin/settings/dm/room/add",                  // POST {materialTypeId, checkpoint}
    deleteRoomFromDM: rootUrl + "/admin/settings/dm/room/delete",            // POST {materialTypeId, checkpoint}

    // DeskCategory
    addDeskCategory: rootUrl + "/admin/settings/desk/category/add", // POST {name, default_text, specific_text}
    getDeskCategories: rootUrl + "/admin/settings/desk/category", // GET
    getDeskCategory: rootUrl + "/admin/settings/desk/category/check/", // GET {name}
    updateDeskCategory: rootUrl + "/admin/settings/desk/category/update", // POST {auditRoomId, updatedField, updatedValue}
    deleteDeskCategory: rootUrl + "/admin/settings/desk/category/delete", // POST {auditRoomId}
    addDeskCategoryFile: rootUrl + "/admin/settings/desk/category/file/", // URL {auditDMId, roomId} - POST {file}

    // DeskProduct
    addDeskProduct: rootUrl + "/admin/settings/desk/product/add", // POST {name, default_text, specific_text}
    getDeskProducts: rootUrl + "/admin/settings/desk/product", // GET
    getDeskProduct: rootUrl + "/admin/settings/desk/product/check/", // GET {name}
    updateDeskProduct: rootUrl + "/admin/settings/desk/product/update", // POST {auditRoomId, updatedField, updatedValue}
    deleteDeskProduct: rootUrl + "/admin/settings/desk/product/delete", // POST {auditRoomId}
    addDeskProductFile: rootUrl + "/admin/settings/desk/product/file/", // URL {auditDMId, roomId} - POST {file}
    addDeskTypeofControl:
        rootUrl + "/admin/settings/desk/product/typeofcontrol/add", // URL {auditDMId, roomId} - POST {file}
    deleteDeskTypeofControl:
        rootUrl + "/admin/settings/desk/product/typeofcontrol/delete", // URL {auditDMId, roomId} - POST {file}
    updateDeskTypeofControl:
        rootUrl + "/admin/settings/desk/product/typeofcontrol/update", // URL {auditDMId, roomId} - POST {file}
    addDeskProductRelated:
        rootUrl + "/admin/settings/desk/product/productrelated/add",
    addDeskCategoryRelated:
        rootUrl + "/admin/settings/desk/product/categoryrelated/add",
    deleteDeskCategoryRelated:
        rootUrl + "/admin/settings/desk/product/categoryrelated/delete",
    deleteDeskProductRelated:
        rootUrl + "/admin/settings/desk/product/productrelated/delete",
    
    // Audit Desk
    getDeskAudit: rootUrl + "/admin/desk/audit/check/", // GET {name}
    getDeskAuditsAdmin: rootUrl + "/admin/desk/audit", // GET
    deleteDeskAudit: rootUrl + "/admin/desk/audit/delete", // POST {auditRoomId}
    sendDeskAudit: rootUrl + "/admin/desk/audit/send", // POST{emailsSendTo , patientId, auditId}

    // Audit settings
    addLogoAuditSettings: rootUrl + "/admin/auditsettings/logo/",                           // ADD
    // addAuditSettings : rootUrl + "/admin/auditsettings/add",                                       // POST
    updateAuditSettings: rootUrl + "/admin/auditsettings/update",                                  // POST
    // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",                                  // POST
    
    // Audit Desk settings
    // getAuditDeskSettingsLogo: rootUrl + "/auditdesksettings/logo/", // URL {generalSettingsId} - GET
    addLogoAuditDeskSettings: rootUrl + "/admin/auditdesksettings/logo/", // ADD
    // addAuditDeskSettings : rootUrl + "/admin/auditdesksettings/add",                                       // POST
    updateAuditDeskSettings: rootUrl + "/admin/auditdesksettings/update", // POST
    // deleteAuditSettings: rootUrl + "/admin/auditsettings/remove",    

    // Audits
    getAuditAdmin: rootUrl + "/admin/audit",                                  // GET
    deleteAudit: rootUrl + "/admin/audit/delete",
    sendAudit: rootUrl + "/admin/audit/send",                                  // POST{emailsSendTo , patientId, auditId}

    // API MUST
    mustFindCli: rootUrl + "/admin/must/client", // GET
    mustFindArticles: rootUrl + "/admin/must/article", // GET
    mustGetEtatDossier: rootUrl + "/admin/must/getetatdossier", //GET
    syncOrderMustAdmin: rootUrl + "/admin/must/savedossier3CE",

    //API LOMACO
    syncOrderLomacoAdmin: rootUrl + "/admin/lomaco/savedossier3CE",
    lomacoFindArticles: rootUrl + "/admin/lomaco/article", //GET
    lomacoFindCli: rootUrl + "/admin/lomaco/client",   //GET
    lomacoGetEtatDossier: rootUrl + "/admin/lomaco/getorderstatus",

    // Documents Database
    createDocument: rootUrl + "/admin/documentsdb/add",                            // POST {data}
    addDocumentFile: rootUrl + "/admin/documentsdb/file/add",                       // POST {formData}
    updateDocument: rootUrl + "/admin/documentsdb/update",                         // POST {data}
    deleteDocument: rootUrl + "/admin/documentsdb/delete",                         // POST {dataId}

    addDocumentsDbCategory: rootUrl + "/admin/documentscategories/add",                  // POST {data}
    updateDocumentsDbCategory: rootUrl + "/admin/documentscategories/update",            // POST {data}
    deleteDocumentsDbCategory: rootUrl + "/admin/documentscategories/delete",            // POST {dataId}

    // Collaborators
    getCollaborators: rootUrl + "/admin/collaborator",                                 // GET
    addCollaborator: rootUrl + "/admin/collaborator/add",                             // POST {data}
    removeCollaborator: rootUrl + "/admin/collaborator/remove/",                       // POST {collaboratorId}
    updateCollaborator: rootUrl + "/admin/collaborator/update",                        // POST {collaboratorId, updatedField, updatedValue}
    addCollaboratorClient: rootUrl + "/admin/collaborator/client/add",   // POST {collaboratorId, clientId}
    removeCollaboratorClient: rootUrl + "/admin/collaborator/client/remove", // POST {collaboratorId, clientId}

    // ======================================================================
    // =============================== ADMIN ================================
    // ======================================================================
    
    //Promo offer
    getPromoOperationsAdmin: rootUrl + "/admin/promo",                                  // GET
    createPromoOperation: rootUrl + "/admin/promo/add",                                  // POST{product_id, mercurial_id, startDate, endDate}
    addPromoOperationFile: rootUrl + "/admin/promo/file/",                                  // POST{file}
    deletePromoOperation: rootUrl + "/admin/promo/delete/",                                  // POST{operationPromotionalId}
    updatePromoOperation: rootUrl + "/admin/promo/update/",                                  // POST{updatedField, updatedValue, promoOperationId}
    
    // Configurables
    addCollaboratorType: rootUrl + "/admin/collaboratortype/add",                      // POST {name}
    removeCollaboratorType: rootUrl + "/admin/collaboratortype/remove",                      // POST {name}

    // General settings
    updateGeneralSettings: rootUrl + "/admin/generalsettings/update",                 // POST {updatedField, updatedValue}
    addLogo: rootUrl + "/admin/generalsettings/logo/",                     // URL {generalSettingsId} - POST {data}
    
};

var sessionJWT = sessionStorage.getItem('jwt');
if (sessionJWT) APIUrl.jwtToken = sessionJWT;

export default APIUrl;
